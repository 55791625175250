import { PollVotingContent } from './PollVotingContent';
import { PollVotingFooter } from './PollVotingFooter';
import { PollVotingHeader } from './PollVotingHeader';
import { PollVotingRoot } from './PollVotingRoot';

export const PollVoting = {
  Root: PollVotingRoot,
  Header: PollVotingHeader,
  Content: PollVotingContent,
  Footer: PollVotingFooter,
};
