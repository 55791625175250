import { ReactNode } from 'react';
import { faClose } from '@fortawesome/pro-regular-svg-icons';

import logomarkSrc from '@/@assets/logomark.svg';
import joinProjectBackgroundSrc from '@/assets/join-project-background.png';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import {
  BackgroundImage,
  CloseButton,
  Content,
  DialogContainer,
  Footer,
  Logomark,
  Scrim,
} from './JoinProjectDIalog.styles';

export interface JoinProjectDialogProps {
  open: boolean;
  subjectName: string;
  MembersList: ReactNode;
  onSignIn: () => void;
}

export function JoinProjectDialog({ open, subjectName, MembersList, onSignIn }: JoinProjectDialogProps) {
  const isMobile = useIsMobileViewport();

  return (
    <RMDialog.Root open={open} variant={isMobile ? 'full-screen' : 'regular'}>
      <RMDialog.Content>
        <DialogContainer>
          <BackgroundImage src={joinProjectBackgroundSrc} />
          <Scrim />

          <Content>
            <Logomark src={logomarkSrc} />
            <RMText type="sans" size="xs" bold color="on-surface-primary" align="center">
              Never miss a story from {subjectName} when you join Remento
            </RMText>
            {MembersList}
          </Content>

          <Footer>
            <RMButton background="primary" fullWidth autoLoading onClick={onSignIn}>
              Sign in / Sign up
            </RMButton>
          </Footer>
        </DialogContainer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
