import { AclGroupInvitee } from './acl';
import { BaseEntity, BaseMutation } from './base-entity';
import { TextPromptTemplate } from './project';

export enum PollStatus {
  ACTIVE = 'active',
  FINISHED = 'finished',
  DELETED = 'deleted',
}

export interface PollPrompt {
  id: string;
  template: TextPromptTemplate | null;
  text: string;
}

export interface Poll extends BaseEntity<PollStatus> {
  projectId: string;
  requesterPersonId: string;
  acl: string[];
  expireOn: number;
  winnerPromptIds: Array<string> | null;
  prompts: Array<PollPrompt>;
}

export enum PollMutationType {
  FINISH = 'finish',
}

export type FinishPollMutation = BaseMutation<PollMutationType.FINISH, { winnerPromptIds: Array<string> }>;

export type PollMutation = FinishPollMutation;

export interface PollShareLink {
  link: string;
  aclTokenId: string;
  createdAt: number;
  invitee?: AclGroupInvitee | null;
}
