import { styled } from '@linaria/react';

import { RMIconButton } from '@/components/RMIconButton/RMIconButton';

export const DialogContainer = styled.div`
  position: relative;
  height: 512px;
  display: flex;
  flex-direction: column;
  align-items: center;

  body[data-mobile='true'] & {
    height: 100%;
  }
`;

export const CloseButton = styled(RMIconButton)`
  position: absolute;
  top: var(--spacing-md);
  right: var(--spacing-md);
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 310px;
  object-fit: cover;
  position: absolute;
`;

export const Scrim = styled.div`
  position: absolute;
  height: 160px;
  width: 100%;
  top: 150px;
  z-index: 1;

  background: linear-gradient(
    180deg,
    rgba(240, 239, 234, 0) 0%,
    rgba(240, 239, 234, 0.0086472) 6.67%,
    rgba(240, 239, 234, 0.03551) 13.33%,
    rgba(240, 239, 234, 0.0816599) 20%,
    rgba(240, 239, 234, 0.147411) 26.67%,
    rgba(240, 239, 234, 0.231775) 33.33%,
    rgba(240, 239, 234, 0.331884) 40%,
    rgba(240, 239, 234, 0.442691) 46.67%,
    rgba(240, 239, 234, 0.557309) 53.33%,
    rgba(240, 239, 234, 0.668116) 60%,
    rgba(240, 239, 234, 0.768225) 66.67%,
    rgba(240, 239, 234, 0.852589) 73.33%,
    rgba(240, 239, 234, 0.91834) 80%,
    rgba(240, 239, 234, 0.96449) 86.67%,
    rgba(240, 239, 234, 0.991353) 93.33%,
    #f0efea 100%
  );
`;

export const Content = styled.div`
  position: relative;
  z-index: 2;
  padding-top: 208px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  margin-bottom: auto;
  width: 250px;
`;

export const Logomark = styled.img`
  height: 64px;
`;

export const Footer = styled.div`
  width: 100%;
  padding: 0 var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);
`;
