import { useMemo } from 'react';

import userImageSrc from '@/assets/user.svg';
import { RMAvatarSharing } from '@/components/RMAvatarSharing';
import { useProjectMembers } from '@/modules/sharing/hooks/useProjectMembers';
import { ActiveMember, Member } from '@/modules/sharing/types';
import { ColorsTokens } from '@/styles/theme/colorTokens';

import { PollVoteAvatarList } from '../components/PollVoteAvatarList/PollVoteAvatarList';

interface PollVoteAvatarListItemContainer {
  member: Member;
}

function PollVoteAvatarListItemContainer({ member }: PollVoteAvatarListItemContainer) {
  if (member.type === 'invited') {
    return null;
  }

  return (
    <RMAvatarSharing.Item
      size="small"
      border="surface"
      imageURL={member.avatarUrl}
      text={member.initials ?? ''}
      backgroundColor={member.avatarColor}
      overlapping={true}
      tooltip={member?.name?.full}
    />
  );
}

export interface PollVoteAvatarListContainerProps {
  projectId: string | null;
}

const MAX_AVATARS = 4;
const lf = new Intl.ListFormat('en');

export function MembersAvatarListContainer({ projectId }: PollVoteAvatarListContainerProps) {
  const members = useProjectMembers(projectId)?.filter((member) => member.type !== 'invited');

  const avatarShowLimit = members && members.length > MAX_AVATARS ? MAX_AVATARS - 1 : MAX_AVATARS;
  const membersExceeded = members ? members.length - avatarShowLimit : 0;

  const description = useMemo(
    () =>
      members &&
      lf.format(
        members
          .filter((member) => member.type !== 'invited')
          .map((member) => (member.type !== 'invited' ? member.name?.first ?? '' : '')),
      ),
    [members],
  );

  const ListItems = useMemo(
    () =>
      members
        ?.slice(0, avatarShowLimit)
        .filter((member) => member.type !== 'invited')
        .map((member, i) => <PollVoteAvatarListItemContainer key={i} member={member} />),
    [avatarShowLimit, members],
  );

  return (
    <PollVoteAvatarList
      votesDescription={description ? `Join ${description}` : ''}
      ListItems={
        <>
          {ListItems}
          {membersExceeded > 0 && (
            <RMAvatarSharing.Item
              size="small"
              border="surface"
              text={`+${membersExceeded}`}
              backgroundColor={ColorsTokens['on-secondary']}
              overlapping={true}
            />
          )}
        </>
      }
    />
  );
}
