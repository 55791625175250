import { styled } from '@linaria/react';

export const Content = styled.div`
  width: 32rem;
  padding: var(--spacing-2xl) var(--spacing-lg) var(--spacing-lg) var(--spacing-lg);
  box-sizing: border-box;

  body[data-mobile='true'] & {
    width: 100%;
    padding: 0 var(--spacing-md) 0 var(--spacing-md);
  }
`;
