import { RMText } from '@/components/RMText/RMText';

import { usePollExpirationTimer } from '../../hooks/poll-timer..hook';

import { CenterText, Header, Timer } from './PollVotingHeader.styles';

interface PollVotingHeader {
  expireOn: number;
}

export function PollVotingHeader({ expireOn }: PollVotingHeader) {
  const timer = usePollExpirationTimer(expireOn);

  return (
    <Header>
      <CenterText>
        <RMText align="center" type="sans" size="xs" bold="medium" color="inverse-on-surface-primary">
          Poll ends in
        </RMText>
        <Timer>
          <RMText size="m" type="sans" bold color="danger-on-inverse-surface">
            {timer.hours}
          </RMText>

          <RMText size="m" type="sans" bold color="danger-on-inverse-surface">
            {timer.minutes}
          </RMText>

          <RMText size="m" type="sans" bold color="danger-on-inverse-surface">
            {timer.seconds}
          </RMText>
        </Timer>
      </CenterText>
    </Header>
  );
}
