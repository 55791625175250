import { styled } from '@linaria/react';

export const Footer = styled.div`
  box-sizing: border-box;
  width: 32rem;
  padding: var(--spacing-xl) var(--spacing-lg) var(--spacing-lg) var(--spacing-lg);
  display: flex;
  gap: var(--spacing-md);

  body[data-mobile='true'] & {
    background: var(--surface);
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: var(--spacing-lg) var(--spacing-md) var(--spacing-md) var(--spacing-md);
    gap: var(--spacing-sm);
  }
`;
